import * as React from 'react';

import { useAuthFunctions } from '../../../library/pld-auth'
import * as utils from '../../../utils'

import {
    LoadingTableBase,
    LoadingTableCell,
    Checkbox,
    HoverContentWithClickCopy,
    TableCell, TableHead, TableRow, TableBody,
    List, ListItem, ListItemButton, ListItemIcon, ListItemText,
    SaveIcon, CloseIcon, EditIcon,
} from '../../../library/pld-ui'


export default function UserManagement(props) {
    const auth = useAuthFunctions()
    const [loading, setLoading] = React.useState(true)
    const [users, setUsers] = React.useState([])
    const [roles, setRoles] = React.useState([])

    const processUserDataResult = React.useCallback((data) => {
        if (Array.isArray(data)) {
            setUsers(data)
        }
        setLoading(false)
    }, [setLoading, setUsers])

    const processRoleDataResult = React.useCallback((data) => {
        if (Array.isArray(data)) {
            setRoles(data)
        }
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/users`, "POST", {}).then((result) => {
            processUserDataResult(result)
        })
    }, [setRoles, auth, processUserDataResult])


    React.useEffect(() => {
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/roles`, "POST", {}).then((result) => {
            processRoleDataResult(result)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <LoadingTableBase loading={loading}>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>UPN</TableCell>
                        <TableCell>Roles</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((item, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <HoverContentWithClickCopy copyValue={item.id}/>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.title}
                            </TableCell>
                            <UserRoleSection userId={item.id} availableRoles={roles} />
                        </TableRow>
                    ))}
                </TableBody>
            </LoadingTableBase>
        </>
    );
}




function UserRoleSection(props) {
    const auth = useAuthFunctions()
    const [loading, setLoading] = React.useState(true)
    const [editSelected, setEditSelected] = React.useState(false)
    const [selectedRoles, setSelectedRoles] = React.useState([])
    const [currentRoleString, setCurrentRoleString] = React.useState("Not yet updated")
    const userId = props.userId
    const availableRoles = props.availableRoles

    const handleSave = React.useCallback(() => {
        var data = {
            userId: userId,
            userRoles: selectedRoles
        }
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/updateuserroles`, "POST", data).then((result) => {
            console.log("user role update response", result)
            
        })
    }, [auth, selectedRoles, userId])

    const toggleSelected = React.useCallback(() => {
        setEditSelected(!editSelected)
    }, [editSelected, setEditSelected])

    const handleRoleSelection = React.useCallback((event, id) => {
        var selected = [...selectedRoles]
        if (event.target.checked) {
            console.log("event target", event.target)
            selected.push(id)
        } else {
            selected = selected.filter((item) => item !== id)
        }
        setSelectedRoles(selected)
    }, [selectedRoles, setSelectedRoles])

    const processRoleDataResult = React.useCallback((data) => {
        console.log("user role response", data)
        if (Array.isArray(data)) {
            console.log("It is an Array")
            var selected = []
            var roleString = ""
            data.forEach((item) => {
                selected.push(item.id)
                roleString += item.title + ", "
                console.log("an item")
            })
            roleString = roleString.substring(0, roleString.length - 2)
            console.log("role string", roleString)
            setCurrentRoleString(roleString)
            setSelectedRoles(selected)
            console.log("done")
        }
        setLoading(false)
    }, [setLoading, setSelectedRoles])

    React.useEffect(() => {
        var data = {
            userId: userId
        }
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/getuserroles`, "POST", data).then((result) => {
            processRoleDataResult(result)
        })
        console.log("available roles", props.availableRoles)
        document.title = 'Chatbot User Management';
        // eslint-disable-next-line
    }, [])
    return (
        <>
                <LoadingTableCell loading={loading} >
                    {
                        editSelected ? (
                            <List>
                                {
                                    availableRoles.map((item, index) => (
                                        <ListItem key={index} disablePadding>
                                            <Checkbox edge="start" tabIndex={-1} disableRipple
                                                checked={selectedRoles.includes(item.id)}
                                                onChange={(event) => handleRoleSelection(event, item.id)}

                                            />
                                            <ListItemText primary={item.title} />
                                        </ListItem>
                                    ))
                                }
                                < ListItem key="update" disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <SaveIcon onClick={handleSave} />
                                            <CloseIcon onClick={toggleSelected} />
                                        </ListItemIcon>
                                    </ListItemButton>
                                </ListItem>
                            </List >
                        ) : (
                            
                            <>
                                <p>{currentRoleString}<EditIcon onClick={toggleSelected} /></p>
                            </>
                        )
                    }
                </LoadingTableCell>

        </>
    );
}