const themeOptions = {
  palette: {
    primary: {
      main: '#187770',
    },
    secondary: {
      main: '#2c728a',
    },
    text: {
      primary: '#f4f7f9',
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro',
    htmlFontSize: 16,
    fontSize: 16,
  },
  shape: {
    borderRadius: 10
  }
};

export default themeOptions