import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

window.React = React

const rootDiv = document.getElementById('pldchatbot')

//// check query string parameters for data-attributes, its supposed to work when we are using it as iframe
const qryStrings = new URLSearchParams(window.location.search)

/// Setter: Set data from query string
if (qryStrings.get("conversation_type"))
  rootDiv.setAttribute('data-conversation-type', qryStrings.get("conversation_type"))

if (qryStrings.get("primary_color"))
  rootDiv.setAttribute('data-chat-primary-color', qryStrings.get("primary_color"))

if (qryStrings.get("secondary_color"))
  rootDiv.setAttribute('data-chat-secondary-color', qryStrings.get("secondary_color"))

if (qryStrings.get("primary_text"))
  rootDiv.setAttribute('data-chat-primary-text', qryStrings.get("primary_text"))

if (qryStrings.get("font_family"))
  rootDiv.setAttribute('data-chat-font-family', qryStrings.get("font_family"))

if (qryStrings.get("font_size"))
  rootDiv.setAttribute('data-chat-font-size', qryStrings.get("font_size"))

if (qryStrings.get("border_radius"))
  rootDiv.setAttribute('data-chat-border-radius', qryStrings.get("border_radius"))

if (qryStrings.get("stack_quick_action_buttons"))
  rootDiv.setAttribute('data-widget-stack-quick-action-buttons', qryStrings.get("stack_quick_action_buttons"))

if (qryStrings.get("enable_loader"))
  rootDiv.setAttribute('data-enable-loader', qryStrings.get("enable_loader"))

ReactDOM.render(
  <React.StrictMode>
       <App />
  </React.StrictMode>,
  document.getElementById('pldchatbot')
);

serviceWorker.unregister();
