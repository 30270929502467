import React from "react";
import {
  InputBase,
} from "@mui/material";
import {
  Search as SearchIcon,
} from "@mui/icons-material";
import classNames from "classnames";

import { DefaultStyles } from "../..";

// TODO: This needs to actually provide input and feedback to the search

export default function SearchBar(props) {
  const classes = DefaultStyles();
  const showSearchBar = props.showSearchBar;

  const [isSearchOpen, setSearchOpen] = React.useState(false);

  return (
    <>
      <div className={classes.grow} />
      {showSearchBar &&
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
            <SearchIcon classes={{ root: classes.headerIcon }} />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </div>
      }
    </>
  );
}
