import React from "react";
import { ChatProvider } from "../..";

export default function ChatBase(props) {
  return (
        <ChatProvider config={props.config}>
          {props.children}
        </ChatProvider>
  );
}
