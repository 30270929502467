import React, { forwardRef, useImperativeHandle } from 'react';

import {
  Launcher, ChatBase, Conversation, ConversationContainer, ChatController,
  useChatSettings, useChatFunctions
} from "../..";

export default function ChatWidget(props) {
  var backupController = ChatController();
  var chatController = props.chatController || backupController;
  return (
    <ChatBase>
      <InnerChatWidget chatController={chatController} {...props} />
    </ChatBase>
  )
}

const InnerChatWidget = forwardRef((props, ref) => {
  const chatController = props.chatController;
  const chatSettings = useChatSettings();
  const chatFunctions = useChatFunctions();

  useImperativeHandle(chatController.ref, () => ({
    log() { console.log("ChatWidget: ", chatSettings, chatFunctions) },
    ToggleChatWindow() { chatFunctions.launcherSelected(); },
    setConfigValue(key, value) { chatFunctions.setConfigValue(key, value) },
    clearMessages() { chatFunctions.clearMessages() },
    addResponseMessage(message) { chatFunctions.addResponseMessage(message) },
    addUserMessage(message) { chatFunctions.addUserMessage(message) },
    markAllAsRead() { chatFunctions.markAllAsRead() },
  }));

  React.useEffect(() => {
    if (chatController.ref && chatController.ref.current) {
      chatFunctions.updateFromConfig(chatController.config);
    }
  }, [chatController.ref])

  //chatFunctions.setConfigValue("displayHeader", true);
  return (
    <>
      {
        chatSettings.format === "bubble" && (
          <>
            <ConversationContainer>
              <Conversation />
            </ConversationContainer>
            <Launcher />
          </>
        )
      }
      {
        chatSettings.format === "static" && (
          <>
              <Conversation />
          </>
        )
      }
    </>
  );
})