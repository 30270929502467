import {useRef, useEffect, useContext } from "react";
import * as AdaptiveCards from "adaptivecards";
import * as MarkdownIt from "markdown-it"
import { HostConfigContext } from '../global/context'

const md = new MarkdownIt();

AdaptiveCards.onProcessMarkdown = function(text, result) {
  // var p = document.createElement("p");
  // p.innerText = text;
  result.outputHtml = md.render(text);
  result.didProcess = true;
}

export default function PAdaptiveCard (props) {
  const cardWrapperRef = useRef();
  const hostConfig = useContext(HostConfigContext);

  useEffect(() => {
    if (!cardWrapperRef || !props.card) return;
    const adaptiveCard = new AdaptiveCards.AdaptiveCard()
    adaptiveCard.hostConfig = new AdaptiveCards.HostConfig(hostConfig);
    adaptiveCard.onExecuteAction = function(action) {
      if (props.onExecuteAction) {
        if (typeof props.onExecuteAction === "function"){
          props.onExecuteAction(action)
        }
      }
    }

    adaptiveCard.onInlineCardExpanded = (action, isExpanded) => {
      if (isExpanded)
        action.card.renderedElement.scrollIntoView({ behavior: 'smooth' });
    }

    adaptiveCard.parse(props.card);
    adaptiveCard.render(cardWrapperRef.current);
    // eslint-disable-next-line
  }, [props.card, cardWrapperRef]);

  return <div ref={cardWrapperRef} />;
};
