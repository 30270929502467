import React from "react"; 
import { 
  useChatSettings, useChatFunctions,
 } from "../..";
 import { 
  IconButton,
  CloseIcon,
 } from "../../../pld-ui";

export default function ConversationHeader(props) {
  const chatSettings = useChatSettings();
  const chatFunctions = useChatFunctions();

  const handleClose = () => {
    chatFunctions.closeChat();
  };

  const headerStyle = {
    borderTopLeftRadius: chatSettings.borderRadius,
    borderTopRightRadius: chatSettings.borderRadius,
    background: chatSettings.headerBackground,
    height: chatSettings.headerHeight,
    width: "100%",
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    alignSelf: 'flex-start',
  };
  const headerTextStyle = {
    color: chatSettings.headerTextColor,
    verticalAlign: 'middle',
  }

  return (
    <>
      {chatSettings.displayHeader && (
        <div style={headerStyle}>
          <h1 style={headerTextStyle}>{chatSettings.headerText}</h1>
          <IconButton
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </>
  );
}
