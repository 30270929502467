import React from 'react'
import { Grid } from '../..'

export default function GridWrapper(props) {
    const gridSpacing = props.spacing || 3
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    {props.children}
                </Grid>
            </Grid>
        </Grid>
    )
}

