import React from "react";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "../..";

import { LoginPage } from "../..";

export default function AuthProtectedPage(props) {
  const LoginComponent = props.loginComponent || LoginPage

  return (
    <>
      <AuthenticatedTemplate>
        {props.children}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginComponent/>
      </UnauthenticatedTemplate>
    </>
  );
}
