import * as React from 'react';

import { useAuthFunctions } from '../../../library/pld-auth'
import { ChatWidget, ChatController } from '../../../library/PLD-Chat-Widget'
import { GridWrapper, GridButton, GridTileItem, DisplayCard, IconButton, ChatBubbleIcon } from '../../../library/pld-ui';
import * as utils from '../../../utils'

import {
    LoadingTableBase,
    LoadingTableCell,
    Checkbox,
    HoverContentWithClickCopy,
    TableCell, TableHead, TableRow, TableBody,
    List, ListItem, ListItemButton, ListItemIcon, ListItemText,
    SaveIcon, CloseIcon, EditIcon,
} from '../../../library/pld-ui'

// eslint-disable-next-line
export default function (props) {
    const auth = useAuthFunctions()
    const [loading, setLoading] = React.useState(true)
    const [sessions, setSessions] = React.useState([])

    const onRowSelected = (event, id) => {
        console.log("onRowSelected", event, id)
        // Call navigate to room if it is a function
        if (typeof props.connectToNewRoom === "function") {
            props.connectToNewRoom({roomId: id})
        }

    }
    
    const processHistoryDataResult = React.useCallback((data) => {
        console.log("processHistoryDataResult", data)
        if (Array.isArray(data.sessions)) {
            setSessions(data.sessions)
        }

        setLoading(false)
    }, [setLoading, setSessions])

    React.useEffect(() => {
        auth.authenticatedCall(`${utils.getApiEndpoint()}/ai/history`, "POST", {}).then((result) => {
            processHistoryDataResult(result)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <LoadingTableBase loading={loading}>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Continue</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sessions.map((item, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {item.id}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.state}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <IconButton aria-label="continue" onClick={(event) => { onRowSelected(event, item.typeId) }}> 
                                    <ChatBubbleIcon /> 
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </LoadingTableBase>
        </>
    );
}

