import React from 'react'

import * as utils from '../../../utils'

import {
    useAuthFunctions
} from '../../../library/pld-auth'
import {
    LineChart,
    DisplayCard,
    GridTileItem,
    Typography,
    LoadingCard,
} from '../../../library/pld-ui'

export default function JMetricsCard(props) {
    const auth = useAuthFunctions()
    const [loading, setLoading] = React.useState(true)
    const [jMetrics, setJMetrics] = React.useState({})

    const getJMetricValue = React.useCallback((metricName, defaultValue) => {
        return jMetrics[metricName] || defaultValue
    }, [jMetrics])

    const processDataResult = React.useCallback((data) => {
        setJMetrics(data)
        setLoading(false)
    }, [setJMetrics, setLoading])

    React.useEffect(() => {
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/jmetrics`, "POST", {}).then((result) => {
            processDataResult(result)
        })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <GridTileItem>
                <DisplayCard
                    loading={loading}
                    title="JMetrics">
                    <Typography variant="h6">Active WSS: </Typography>
                </DisplayCard>
            </GridTileItem>
        </>
    )
}

