import * as React from 'react';
import {
    Table, TableContainer,
    SkeletonTable,
} from '../../'


export default function LoadingTableBase(props) {
    const loading = props.loading || false

    return (
        <>
            {loading ? (
                <SkeletonTable />
            ) : (
                <TableContainer>
                    <Table aria-label="simple table">
                        {props.children}
                    </Table>
                </TableContainer>)
            }
        </>
    );
}