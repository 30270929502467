import React from 'react'

import {
    NavigationDrawerContentBuilder,
    Loading,
    TryIcon, ChatBubbleIcon, ViewAgendaIcon, HistoryIcon
} from '../../library/pld-ui'
import { useAuthFunctions } from '../../library/pld-auth'
import * as utils from 'utils'

import {
    AuthProtectedPage,
    AuthenticatedAppBar,
} from '../../library/pld-auth-ui'

import DefaultChat from './DefaultChat'
import AdvancedChat from './AdvancedChat'
import DirectInput from './DirectInput'
import History from './History'

// eslint-disable-next-line
export default function AI() {
    const auth = useAuthFunctions()

    const connectToNewRoom = React.useCallback((configuration) => {
        if (!configuration) {configuration = {}}
        let roomId = configuration.roomId || utils.newGuid()
        console.log("confiuration: ", configuration)
        console.log("roomId: ", roomId)
        const newDefaultChatComponent = {
            title: "New Default Chat",
            component: <DefaultChat />,
            props: {roomId: roomId},
        }
        setComponentToRender(componentMap['#Processing'])
        setTimeout(() => {
            setComponentToRender(newDefaultChatComponent)
        }, 1000)
    }, [])

    const componentMap = {
        'default': {
            title: "AI Beta",
            component: <DefaultChat />,
            props: {},
        },
        '#NewDefaultChat': {
            title: "New Default Chat",
            component: <DefaultChat />,
            props: {},
        },
        '#NewAdvancedChat': {
            title: "New Advanced Chat",
            component: <AdvancedChat />,
            props: {},
        },
        '#DirectInput': {
            title: "Direct Input",
            component: <DirectInput />,
            props: {},
        },
        '#History': {
            title: "History",
            component: <History />,
            props: {connectToNewRoom: connectToNewRoom},
        },
        '#Processing': {
            title: "Processing",
            component: <Loading />,
            props: {},
        },

    }
    const [componentToRender, setComponentToRender] = React.useState(componentMap[window.location.hash] || componentMap['default'])

    const onNewDefaultChatClick = React.useCallback(() => {
        connectToNewRoom()
    }, [])

    
    const onHistoryClick = React.useCallback(() => {
        setComponentToRender(componentMap['#History'])
    }, [])

    const drawerBarTemplate = [
        {
            title: 'New Default Chat',
            icon: <ChatBubbleIcon />,
            onClick: onNewDefaultChatClick
        },
        // {
        //     title: 'New Advanced Chat',
        //     icon: <TryIcon />,
        //     onClick: () => { window.location.hash = '#NewAdvancedChat' }
        // },
        // {
        //     title: 'Direct Input',
        //     icon: <ViewAgendaIcon />,
        //     onClick: () => { window.location.hash = '#DirectInput' }
        // },
        {
            title: 'History',
            icon: <HistoryIcon />,
            onClick: onHistoryClick
        },
        {
            title: 'Loading',
            icon: <HistoryIcon />,
            onClick: () => { window.location.hash = '#Processing' }
        },
    ]
    const drawerContent = NavigationDrawerContentBuilder({ drawerContent: drawerBarTemplate })

    React.useEffect(() => {
        auth.authenticatedCall(`${utils.getApiEndpoint()}/checkgroups`, "POST", {}).then((result) => {
            console.log("group check", result)
        })
        // window.addEventListener('hashchange', () => {
        //     console.log("new window hash: " + window.location.hash)
        //     setComponentToRender(componentMap[window.location.hash] || componentMap['default'])
        // })
        document.title = "AI Beta"
        // eslint-disable-next-line
    }, [])

    return (
        <AuthProtectedPage>
            <AuthenticatedAppBar
                title={componentToRender.title || "AI Beta"}
                drawerContent={drawerContent}
            />
            {React.cloneElement(componentToRender.component, componentToRender.props)}
        </AuthProtectedPage>
    )
}


