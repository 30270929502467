import React from "react";
import { useChatSettings } from "../..";
import { Button, Stack } from "../../../pld-ui";

export default function ConversationButtons(props) {
  const chatSettings = useChatSettings();

  const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    background: chatSettings.buttonContainerBackground,
    position: 'relative',
    alignSelf: 'flex-start',
    overflowX: 'wrap',
  };

  const buttonStackStyle = {
    // display: 'flex',
    flexWrap: 'wrap', 
    gap: 1
  }; 

  const buttonStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100%',
    height: '100%',
    background: chatSettings.buttonBackground,
    borderRadius: chatSettings.borderRadius,
    color: chatSettings.buttonTextColor,
    fontSize: chatSettings.buttonFontSize,
    fontWeight: chatSettings.buttonFontWeight,
    padding: chatSettings.buttonPadding,
    margin: chatSettings.buttonMargin,
    color: chatSettings.buttonTextColor,
  };


  var sample_buttons = [
    {
      text: "Button1",
      onClick: () => {console.log("Button 1 clicked");}
    },
    {
      text: "Button2",
      onClick: () => {console.log("Button 2 clicked");}
    },
    {
      text: "Button3",
      onClick: () => {console.log("Button 3 clicked");}
    },
    {
      text: "Button4",
      onClick: () => {console.log("Button 4 clicked");}
    }
  ]

  return (
    <>
      {chatSettings.displayButtonInput && (
        <div style={buttonContainerStyle}>
          <Stack direction="row" spacing={2} style={buttonStackStyle}>
          {sample_buttons.map((button, index) => {
            return (
              <Button key={index} style={buttonStyle} onClick={button.onClick}>{button.text}</Button>
            );
          })}
          </Stack>
        </div>
      )}
    </>

  );
}
