import React from "react";
import { Menu } from "@mui/material";

import { DefaultStyles } from "../../../pld-ui";

import AuthenticatedAvatar from "../AuthenticatedAvatar";
import AuthenticatedProfileCard from "../AuthenticatedProfileCard";

export default function AuthenticatedProfileMenu(props) {
  const classes = DefaultStyles();
  const [profileMenu, setProfileMenu] = React.useState(null);

  return (
    <>
      <AuthenticatedAvatar
        onClick={e => setProfileMenu(e.currentTarget)}
      />
      <Menu
        id="profile-menu"
        open={Boolean(profileMenu)}
        anchorEl={profileMenu}
        onClose={() => setProfileMenu(null)}
        className={classes.headerMenu}
        classes={{ paper: classes.profileMenu }}
        disableAutoFocusItem
      >
        <AuthenticatedProfileCard />
      </Menu>
    </>
  );
}
