import * as React from 'react';

import { useAuthFunctions } from '../../../library/pld-auth'
import { ChatWidget, ChatController } from '../../../library/PLD-Chat-Widget'
import { GridWrapper, GridButton, GridTileItem, DisplayCard } from '../../../library/pld-ui';

// eslint-disable-next-line
export default function (props) {
    const auth = useAuthFunctions()
    var chatController = ChatController() // React.useRef();

    React.useEffect(() => {
        auth.getUser().then((result) => {
            // console.log("gotten_user: ", result)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <h1>Direct Input Coming Soon</h1>
        </>
    );
}

