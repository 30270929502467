import config from './config.json';
import axios from 'axios';

// Use dummy function as default
export default function pleaseStopMessingWithThis() {
    console.log('You shouldn\'t be here')
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/////////////////////////////////
// Common Values functionality //
/////////////////////////////////

export function getEnvironment() {
    // if we are running in a dev environment, local environmen
    if (window.location.hostname.includes('localhost') || window.location.hostname.startsWith('192.168.')) {
        return "local"
    }

    // Try to use the endpoint to determine the environment
    let endpoint = window.location.host
    try {
        var attribute_endpoint = document.getElementById("pldchatbot").getAttribute('endpoint')
        if (attribute_endpoint) {
            endpoint = attribute_endpoint
        }
    } catch (error) {
        console.log("No endpint variable configuration found")
    }

    // Try to use the endpoint to determine the environment
    if (endpoint.endsWith("chatbot.prologis.com") || endpoint.endsWith("chatbot.prologisweb.com")) {
        let endpoint_components = endpoint.split(".")
        if (endpoint_components[0] === "chatbot") {
            return "prod"
        } else {
            return endpoint_components[0]
        }
    }

    // If none of the above are true, default to prodendpoit
    return "prod"
}

export function getEnvironmentConfigValue(key) {
    let environment = getEnvironment()
    console.log("environment", environment)
    return config.environments[environment][key]
}

export function getEndpoint() {
    // if we are running in a dev environment, use the config endoint
    if (window.location.hostname.includes('localhost') || window.location.hostname.startsWith('192.168.')) {
        // console.log("Running in development mode, targeting dev endpoint")
        return config.endpoint;
    }

    // if we can find an "endpoint" attribute in the root object, use that
    try {
        var attributeendpoint = document.getElementById("pldchatbot").getAttribute('endpoint')
        if (attributeendpoint) {
            return attributeendpoint
        }
    } catch (error) {
        console.log("No endpint variable configuration ound")
    }

    // If I am on one of the direct pages use the page config.
    let hostendpoint = window.location.host
    if (hostendpoint.endsWith("chatbot.prologis.com") || hostendpoint.endsWith("chatbot.prologisweb.com")) {
        return hostendpoint
    }

    // If none of the above are true, default to prodendpoit
    return "prod.chatbot.prologis.com"
}

export function getApiEndpoint() {
    return `https://${getEndpoint()}/api`
}

// Only use this function when you are on the main sight, it won't be able to get the environment 
// from the embedding level
export function getTeamsBotLink() {
    let endpoint = getEndpoint()
    console.log(endpoint)
    let environment_code = endpoint.split(".")[0]
    return config.teams_bot_link[environment_code]
}

////////////////////////
// REST functionality //
////////////////////////

export function postAPI(path, body, requestConfig) {
    // Pull in admin token if it is around
    let token = getURLQueryParam('id') || ""
    // Determine proper endpoint
    let endpoint = getApiEndpoint()

    // Build call structure
    if (!requestConfig) {
        requestConfig = {}
    }
    requestConfig.headers = {
        'accept': "application/json"
    }
    if (token !== "") {
        requestConfig.headers.Authorization = token
    }

    // Check for a session ID, include it in the request
    let sessionId = localStorage.getItem('session_id')
    if (sessionId) {
        if (!body) {
            body = {}
        }
        body.session_id = sessionId
    }

    return axios.post(endpoint + path, body, requestConfig)
}

/////////////////////////////
// Websocket functionality //
/////////////////////////////

export function getRoom() {
    if (!getEndpoint()) {
        return null
    }
    var room = localStorage.getItem("room")
    if (room !== null) {
        return { name: room, new: false }
        // return room
    }
    else {
        let newroom = newGuid()
        localStorage.setItem("room", newroom)
        return { name: newroom, new: true }
        //return newroom
    }
}

export function clearRoom() {
    localStorage.removeItem("room")
}

////////////////////////
// GUID functionality //
////////////////////////
export function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}
export function newGuid() {
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}


export function getURLQueryParam(paramName) {
    const queryParams = new URLSearchParams(window.location.search)
    return queryParams.get(paramName)
}


////////////////////////
// GUID functionality //
////////////////////////

// Javascript Deep Compare function compliments of Jean Vincent
// https://stackoverflow.com/a/6713782/4279849

export function compare(a, b) {
    if (a === b) return true
    // if both a and b are null or undefined and exactly the same

    if (!(a instanceof Object) || !(b instanceof Object)) return false
    // if they are not strictly equal, they both need to be Objects

    if (a.constructor !== b.constructor) return false
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in a) {
        if (!a.hasOwnProperty(p)) continue
        // other properties were tested using a.constructor === b.constructor

        if (!b.hasOwnProperty(p)) return false
        // allows to compare a[ p ] and b[ p ] when set to undefined

        if (a[p] === b[p]) continue
        // if they have the same strict value or identity then they are equal

        if (typeof (a[p]) !== 'object') return false
        // Numbers, Strings, Functions, Booleans must be strictly equal

        if (!compare(a[p], b[p])) return false
        // Objects and Arrays must be tested recursively
    }

    for (p in b) {
        if (b.hasOwnProperty(p) && !a.hasOwnProperty(p)) return false
        // allows a[ p ] to be set to undefined
    }
    return true
}

