import React, { useRef, useState, useEffect } from 'react';
import * as util from 'utils'
import querySearch from "stringquery";
import { flattenObject } from 'library/pld-helpers'

import { ChatWidget, ChatController } from 'library/PLD-Chat-Widget'

// eslint-disable-next-line
export default function ChatWidgetV3(props) {

    const webSocket = useRef(null)
    const [endpoint, ] = useState(util.getEndpoint())
    const [room, ] = useState(props.room || util.getRoom()) // || {"name": util.newGuid()})
    console.log("room", room)
    const auth = props.auth || undefined
    const idtoken = querySearch(window.location.search).idtoken || ''
    const [botConnected, setBotConnected] = useState(false)

    const displayLoader = React.useCallback((loaderVisibility) => {
        // setLoaderVisibility(loaderVisibility)
    }, [])

    

    const getContext = React.useCallback( () => {
        var context = {}
        context.origin = window.location.origin
        if (props.getContext && typeof props.getContext === 'function') {
            context.parent = props.getContext()
        }
        return context
    }, [props])

    const handleServerMessage = React.useCallback( (chat_event) => {
        console.log("Server Message:", chat_event)
        chat_event.events.forEach(event => {
            if (event.type === "text") {
                if (!event.content.startsWith("__")) {
                    chatController.addResponseMessage(event.content)
                }
            }
            if (event.type === "link") {
                console.log("link snippits are not currently supported in v3")
            }
            if (event.type === "AdaptiveCard") {
                console.log("adaptive cards are not currently supported in v3")
                chatController.addResponseMessage("Adaptive Card Placeholder")
            }
        })

    }, [])

    const handleUserMessage = React.useCallback( (chat_event) => {
        chat_event.events.forEach(event => {
            if (event.type === "text" && !event.content.startsWith("__")) {
                chatController.addUserMessage(event.content)
            }
        })
    }, [])

    const handleChatHistory = React.useCallback( (body) => {
        const eventHistory = body.chat_history || []
        if (eventHistory.length > 0) {
            /// clear chat if no message were sent in last 24 hrs

            // sort based on timestamp in ascending order
            eventHistory.sort((a,b) => a.event_timestamp - b.event_timestamp);

            /// take latest message
            const mostRecentMessage = eventHistory[eventHistory.length - 1]
            /// Be Alert, Date.now give timestamp with miliseconds, timestamp saved in database doesn't have it
            const currentTimeStamp = Math.floor((new Date().getTime()) / 1000)
            var diff =(currentTimeStamp - mostRecentMessage.event_timestamp) / 1000;
            diff /= (60 * 60);

        }

        chatController.clearMessages(1000)
        if (eventHistory.length === 0){
            // sendMessageToBackend("help")
            chatController.addResponseMessage("Hi I'm the messaging assistant, what can I help you with?")
        }
        else {

            /// What is this guy doing here too!!
            chatController.clearMessages(1000)
            eventHistory.forEach(chat_event => {
                if (chat_event.type === "system_message") {
                    handleServerMessage(chat_event)
                }
                if (chat_event.type === "user_message") {
                    handleUserMessage(chat_event)
                }
            })
            chatController.markAllAsRead()
        }
    }, [handleServerMessage, chatController])

     // Websocket components
     const postToWebsocket = React.useCallback((body) => {
        try {
            body.context = getContext()
        } catch (error) {
            console.log("unable to get context for message")
        }
        try {
            webSocket.current.send(JSON.stringify(body))
        } catch (error) {
            console.log("Error sending to the websocket:", error)
        }
    }, [webSocket, getContext])

     const ping = React.useCallback(() => {
        //webSocket.current.send("__ping__")
        postToWebsocket({"type": "ping"})
        setTimeout(ping, 60000)
    }, [postToWebsocket])

    const onWebSocketConnect = React.useCallback((event) => {
        console.log("Chatbot Connected")
        displayLoader(false)
        ping()
        // Note you can't use postToWebsocket here because it doesn't think
        // it has initialized yet
        event.target.send(JSON.stringify({
            "auth_request": {
                "tokens": [idtoken]
            }
        }))
        if (idtoken !== '') {
            event.target.send(JSON.stringify({
                "auth_request": {
                    "tokens": [idtoken]
                }
            }))
        }
        setBotConnected(true)
    }, [idtoken, ping, displayLoader])

    const onWebSocketDisconnect = (event) => {
        // console.log("Disconnected")
        // console.log(event)
        if (![1000, 1006].includes(event.code)){
            chatController.addResponseMessage("Connection closed due to innactivity.")
        }
    }
    const onWebSocketMessage = React.useCallback( (event) => {
        let data = JSON.parse(event.data)
        if (data.message_type === "system_message"){
            handleServerMessage(data)
            displayLoader(false)
        }
        if (data.message_type === "user_message"){
            // chatController.handleUserMessage(data)
        }
        if (data.message_type === "chat_history") {
            handleChatHistory(data)
        }
    }, [handleServerMessage, handleChatHistory, displayLoader])

    const generateWebsocket = React.useCallback(async () => {
        displayLoader(true)
        var parameters = "?version=2"
        room && room.name && (parameters += "&room=" + room.name)
        var context = flattenObject(getContext())
        context && (
            Object.keys(context).forEach((key) => {
                parameters += "&context." + key + "=" + context[key]
            })
        )
        props.startingContext && (parameters += "&startingContext=" + props.startingContext)
        if (auth) {
            await auth.getToken().then((token) => {
                parameters += "&bearer=" + token
            })
        }
        webSocket.current = new WebSocket("wss://" + endpoint + "/websocket/" + parameters)
        webSocket.current.onopen = (event) => onWebSocketConnect(event)
        webSocket.current.onclose = (event) => onWebSocketDisconnect(event)
        webSocket.current.onmessage = (event) => onWebSocketMessage(event)
    }, [endpoint, room, auth])

    // Interacton with the back end
    const sendMessageToBackend = React.useCallback((message, type='text') => {
        let body = {
            "events": [
                {
                    "type": type,
                    "content": message
                }
            ]
        }
        postToWebsocket(body)
        setTimeout(() => {
            displayLoader(true)
        }, 700);
    }, [ postToWebsocket, displayLoader])

    // How to react to user actions
    const handleNewUserMessage = React.useCallback( (event) => {
        sendMessageToBackend(event)
    }, [ sendMessageToBackend])

    var chatController = ChatController({
        // displayHeader: true,
        handleNewUserMessage: handleNewUserMessage,
        format: "static"
    })

    useEffect(() => {
        generateWebsocket()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <ChatWidget chatController={chatController} />
        </>
    );
}

