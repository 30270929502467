
export const defaultConfig = {
    isOpen: true,
    badgeContent: undefined,
    launcherRadius: 36,
    frameMargin: 10,
    conversationContainerWidth: 300,
    conversationContainerHeight: 500,
    conversationContainerBackground: "#ffffff",

    borderRadius: 10,

    headerBackground: "#187770",
    displayHeader: false,
    headerHeight: 50,
    headerText: "Chat Header",
    headerTextColor: "#FFFFFF",

    displayInput: true,
    displayButtonInput: false,
    displayTextInput: true,
    inputContainerHeight: 50,
    inputContainerBackground: "#f4f7f9",
    inputPlaceholderText: "So, what can I help you with?",
    inputText: "",

    format: "bubble",

    // displayButtons: false,
    buttonContainerHeight: 70,
    buttonContainerBackground: "#00FFFF",
    buttonTextColor: "#FFFFFF",
    buttonBackground: "#2c728a",

    handleNewUserMessage: (newMessage) => { console.log(`New message with no hook to the parent! ${newMessage}`) },

    displayLauncherWhenOpen: true,

    displayMessages: true,
    messages: [],
    messageBackgroundUser: "#2c728a",
    messageBackgroundSystem: "#187770",
    messageTextColor: "#FFFFFF",
    messageMaxWidthMultiplier: 0.75,
    messageCopyButton: false,
    placeholderMessages: [
        {
            "content": "Hello, how can I help you?",
            "type": "text",
            "source": "system"
        },
        {
            "content": "Fix Everything",
            "type": "text",
            "source": "user"
        },
        {
            "content": "ok, I'll get right on that",
            "type": "text",
            "source": "system"
        },
        {
            "content": "This is a super long message in which I just keep talking, and talking. I need to make sure it properly wraps the words",
            "type": "text",
            "source": "system"
        },
        {
            "content": "Fix Everything",
            "type": "text",
            "source": "user"
        },
        {
            "content": "ok, I'll get right on that",
            "type": "text",
            "source": "system"
        },
        {
            "content": "Hello, how can I help you?",
            "type": "text",
            "source": "system"
        },
        {
            "content": "Fix Everything",
            "type": "text",
            "source": "user"
        },
        {
            "content": "ok, I'll get right on that",
            "type": "text",
            "source": "system"
        },
    ]
}