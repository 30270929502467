import * as React from 'react';
import {
    TableCell,
    Skeleton,
} from '../../'


export default function LoadingTableCell({loading, ...rest}) {

    return (
        <TableCell colSpan={rest.colSpan} {...rest}>
            {loading ? (
                <Skeleton variant="rectangular" height={30} />
            ) : (
                <>
                    {rest.children}
                </>
            )}
        </TableCell>
    );
}