import { Skeleton } from '../..';

export default function SkeletonLogo(params) {

    return (
        <>
            <Skeleton variant="rectangular" height={40} />
        </>
    )
}
