import React from "react";
import {
  Typography,
} from "@mui/material";

import { DefaultStyles } from "../../../pld-ui";

import { useAuthFunctions } from "../../../pld-auth";

export default function AuthenticatedProfileCard(props) {
  const classes = DefaultStyles();
  const auth = useAuthFunctions()
  const [graphData, setGraphData] = React.useState(null);

  React.useEffect(() => {
    auth.getUser().then((user) => {
      console.log("user", user)
      setGraphData(user)
    }).catch((error) => {
      console.log("error", error);
    });
    // Do not add auth as a dependency. This will cause an infinite loop.
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={classes.profileMenuUser}>
        <Typography variant="h4" weight="medium">
          {graphData?.displayName}
        </Typography>
        <Typography
          className={classes.profileMenuLink}
          color="primary"
        >
          {graphData?.jobTitle}
        </Typography>
      </div>
      <div className={classes.profileMenuUser}>
        <Typography
          className={classes.profileMenuLink}
          color="primary"
          onClick={() => auth.logout()}
        >
          Sign Out
        </Typography>
      </div>
    </>
  );
}
