import * as React from 'react';

import { useAuthFunctions } from '../../../library/pld-auth'

import ChatWidgetV3 from 'components/ChatWidgetV3'

// eslint-disable-next-line
export default function (props) {
    const auth = useAuthFunctions()
    const room = props.roomId && { name: props.roomId} || undefined

    const getContext = () => {
        return {
            "target_context_name": "PLDAIDefault",
        }
    }

    React.useEffect(() => {
        auth.getUser().then((result) => {
            // console.log("gotten_user: ", result)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <ChatWidgetV3 auth={auth} getContext={getContext} format={"static"} room={room} />
        </>
    );
}

