import React from "react";

import {
  TextField, IconButton,
  SendIcon,
} from "../../../pld-ui";

import { useChatSettings, useChatFunctions } from "../..";


export default function ConversationTextInput(props) {
  const chatSettings = useChatSettings();
  const chatFunctions = useChatFunctions();

  const inputContainerStyle = {
    borderBottomLeftRadius: chatSettings.borderRadius,
    borderBottomRightRadius: chatSettings.borderRadius,
    width: "100%",
    // height: chatSettings.inputContainerHeight,
    background: chatSettings.inputContainerBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    justifySelf: 'flex-end',
  };

  const textInputSignInStyle = {
    borderBottomLeftRadius: chatSettings.borderRadius,
    left: chatSettings.borderRadius / 2,
    // height: chatSettings.inputContainerHeight,
    background: chatSettings.inputContainerBackground,
  }

  const onKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      chatFunctions.sendMessage();
    }
  }

  return (
    <>
      
      {chatSettings.displayTextInput && (
        <div style={inputContainerStyle}>
          <TextField
            style={textInputSignInStyle}
            label={chatSettings.inputPlaceholderText}
            variant="standard"
            size="small"
            fullWidth
            multiline
            maxRows={4}
            onChange={(e) => chatFunctions.setInputText(e.target.value)}
            value={chatSettings.inputText}
            onKeyPress={onKeyPress}
          />
          <IconButton
            onClick={chatFunctions.sendMessage}>
              <SendIcon/>
          </IconButton>
        </div>
      )}
    </>

  );
}
