import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Button,
} from "@mui/material";
import { useHistory } from "react-router-dom";


import { DefaultStyles, Copyright } from "../../../pld-ui";

// context
import { useAuthFunctions } from "../../../pld-auth";

export default function LoginPage(props) {
  const classes = DefaultStyles();
  const auth = useAuthFunctions()
  const redirect_target = props.redirect_target
  let history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    setLoading(true);
    auth.login().then(() => {
      console.log("login success");
      if (redirect_target) {
        history.push(redirect_target);
      }
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.log("error", error);
    });
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>

            <div className={classes.formButtons}>
              {loading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  onClick={handleLogin}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  LOG IN AS PROLOGIS EMPLOYEE
                </Button>
              )}
            </div>
          </React.Fragment>
        </div>
        <Copyright />
      </div>
    </Grid>
  );
}
