import * as React from 'react';

import {
    FormControl, FormGroup, ListItemText, Checkbox, Select, MenuItem, InputLabel, OutlinedInput
} from '../..'
import { callIfFunction } from '../../../pld-helpers';

export default function SimplePicklist(props) {
    const title = props.title || "Picklist"
    const [options, ] = React.useState(props.options || [])
    const [selectedOptions, setSelectedOptions] = React.useState(props.selectedOptions || [])

    const handleChange = React.useCallback((event) => {
        setSelectedOptions(event.target.value);
        callIfFunction(props.onChange, event)
    }, [setSelectedOptions, props.onChange])

    
    return (
        <>
            <FormControl component="fieldset" sx={{ m: 1, minWidth: 150 }}>
                <FormGroup>
                    <InputLabel id="demo-multiple-checkbox-label">{title}</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedOptions}
                        onChange={handleChange}
                        input={<OutlinedInput label={title} />}
                        renderValue={(selected) => selected.length.toString() + " selected"}
                    >
                        {options.map((option) => (
                            <MenuItem key={option} value={option}>
                                <Checkbox checked={selectedOptions.indexOf(option) > -1} />
                                <ListItemText primary={option} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormGroup>
            </FormControl>
            
        </>
    );
}
