import React from "react";
import ReactMarkdown from 'react-markdown';

import gfm from 'remark-gfm';
import hljs from 'highlight.js';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { lowlight } from 'lowlight/lib/core';

import {
  useChatSettings,
} from "../..";
import {
  Card,
  ListItem,
  IconButton,
  ContentCopyIcon,
  CardHeader,
  CardContent,
  Typography,
} from "../../../pld-ui"

import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';

lowlight.registerLanguage('javascript', javascript);
lowlight.registerLanguage('python', python);

export default function Message(props) {
  var message = props.message;
  var index = props.index;
  var source = message.source || "user";
  var messageType = message.type || "text";

  const messageContainerStyle = {
    display: 'flex',
    alignSelf: source === "user" ? "flex-end" : "flex-start",
    flexdirection: "row",
  };

  return (
    <div key={index} style={messageContainerStyle}>
      <ListItem key={index} style={messageContainerStyle}>
        {messageType === "text" && (<TextMessage message={message} />)}
      </ListItem>
    </div>
  );
}

export function TextMessage(props) {
  const chatSettings = useChatSettings();
  var message = props.message;
  var content = message.content;
  var source = message.source || "user";
  var isUserMessage = source === "user";
  var background = isUserMessage ? chatSettings.messageBackgroundUser : chatSettings.messageBackgroundSystem;

  const messageStyle = {
    display: 'flex',
    paddingLeft: '5px',
    paddingRight: '5px',
    background: background,
    color: chatSettings.messageTextColor,
    py: '0px',
    my: '0px',
    pb: '0px',
    marginBlockStart: '0px',
    maxWidth: '80%',
    // maxWidth: chatSettings.conversationContainerWidth * chatSettings.messageMaxWidthMultiplier,
    sx: {
      paddingBottom: '0px',
      marginBottom: '0px',
    },
  };

  const cardContentStyle = {
    sx: {
      paddingBottom: '0px',
      marginBottom: '0px',
      paddingTop: '0px',
      marginTop: '0px',
    },
  };
  const cardContentSX = {
    paddingBottom: '0 !important',
    marginBottom: '0px',
    paddingTop: '0px',
    marginTop: '0px',
  };

  const messageContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: '0px',
  };

  const replaceSingleLineBreaksWithSpaces = (input) => {
    return input.replace(/(^|[^\\])\n(?!\n)/g, '$1  \n');
  };

  // const autoDetectLanguage = (value) => {
  //   const result = lowlight.highlightAuto(value);
  //   return result.language;
  // };

  const autoDetectLanguage = (value) => {
    const supportedLanguages = ['javascript', 'python', 'powershell', 'markdown', 'hcl', 'json'];
    const result = hljs.highlightAuto(value, supportedLanguages);
    return result.language;
  };

  const CodeBlockV2 = ({ value }) => {
    const language = autoDetectLanguage(value);
    const codeBlockStyle = {
      // overflowX: 'auto', // Enable horizontal scrolling
      whiteSpace: 'pre', // Preserve white spaces and line breaks
      borderRadius: '3px', // Optional: Add rounded corners
    };
    return (
      <Card >
      <CardHeader >
          <Typography variant="caption" align="left">
            {"language: " + language}
          </Typography>
          <IconButton onClick={() => navigator.clipboard.writeText(value)} size="small" sx={{ float: 'right' }}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </CardHeader>
        <CardContent >
        <SyntaxHighlighter
          language={language}
          style={docco}
          customStyle={codeBlockStyle}
        >
          {value}
        </SyntaxHighlighter>
      </CardContent>
      </Card>
    );
  };


  const CodeBlock = ({ value }) => {
    const language = autoDetectLanguage(value);
    const codeBlockStyle = {
      overflowX: 'auto', // Enable horizontal scrolling
      whiteSpace: 'pre', // Preserve white spaces and line breaks
      borderRadius: '3px', // Optional: Add rounded corners
      maxWidth: '100%',
    };
    return (
      <div style={{ position: 'relative' }}> {/* Wrap SyntaxHighlighter and CopyButton with a div */}
        <div
          style={{
            backgroundColor: '#f5f5f5',
            borderTopLeftRadius: '3px',
            borderTopRightRadius: '3px',
            padding: '4px 8px',
            // display: 'flex',
            // justifyContent: 'space-between',
          }}
        >
          <Typography variant="caption" color="text.secondary">
            {"language: " + language}
          </Typography>
          <IconButton onClick={() => navigator.clipboard.writeText(value)} size="small" sx={{ float: 'right' }}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </div>
        <SyntaxHighlighter
          language={language}
          style={docco}
          customStyle={codeBlockStyle}
        >
          {value}
        </SyntaxHighlighter>
      </div>
    );
  };


  return (
    <Card style={messageStyle}>
      {chatSettings.messageCopyButton && (
        <CardHeader action={
          <IconButton onClick={() => navigator.clipboard.writeText(content)}>
            <ContentCopyIcon />
          </IconButton>
        } />
      )}
      <CardContent sx={cardContentSX} style={cardContentStyle}>
        <ReactMarkdown
          remarkPlugins={[gfm]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const value = children.length ? children[0] : '';

              return !inline ? (
                <CodeBlock value={value} {...props} />
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
          style={messageContentStyle}
        >
          {isUserMessage ? replaceSingleLineBreaksWithSpaces(content) : content}
        </ReactMarkdown>
      </CardContent>
    </Card>
  );
}
