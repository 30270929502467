import React from 'react';


// third-party
import Chart from 'react-apexcharts';

export default function LineChart(props) {
    const [series, ] = React.useState(props.multiSeries ?  props.multiSeries : [{
        name: props.itemName || 'Value',
        data: props.series

    }])

    const options = {
        chart: {
            height: 90,
            type: 'line',
            toolbar: {
                show: false
            }, 
            sparkline: {
                enabled: true
            },
            zoom: {
                autoScaleYaxis: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: props.title,
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        // xaxis: {
        //     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        // },
        xaxis: {
            type: 'datetime',
            labels: {
                show: true,
                format: 'dd MMM yyyy',
            }
        },
        tooltip: {
            theme: 'dark',
            fixed: {
                enabled: false
            },
            x: {
                format: 'dd MMM yyyy'
            },
            y: {
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                  return value + ""
                }
            },
            marker: {
                show: false
            }
        },
        noData: {
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: '#fff',
                fontSize: '14px',
                fontFamily: undefined
            }
        },
    }


    return (


        <div id="chart">
            <Chart options={options} series={series} type="line" height={350} />
        </div>


    )
}

