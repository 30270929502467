import * as React from 'react';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';


import { useAuthFunctions } from '../../../library/pld-auth'
import * as utils from '../../../utils'

import {
    LoadingTableBase,
    LoadingTableCell,
    Box,
    styled,
    Checkbox,
    Typography,
    Table,
    IconButton,
    Collapse, Paper,
    HoverContentWithClickCopy,
    TableCell, TableHead, TableRow, TableBody, TableContainer,
    List, ListItem, ListItemButton, ListItemIcon, ListItemText,
    SaveIcon, CloseIcon, EditIcon,
    ExpandMoreIcon, ExpandLessIcon,
    CircularProgress,
    Divider,
    Button,
} from '../../../library/pld-ui'

const exportToExcel = (data, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: fileType });

    saveAs(dataBlob, fileName + fileExtension);
};


const groupTicketsByTemporalGroups = (tickets) => {
    const groupings = {
        values: {},
        sub_columns: [
            { title: 'Year', field: 'title' },
            { title: 'Count', field: 'count', align: 'right' },
        ]
    };
    Object.entries(tickets).forEach(([ticketId, ticket]) => {
        const created_on_date = new Date(ticket.sys_created_on)
        const ticket_id = ticket.number;
        const ticketCreationYear = created_on_date.getFullYear();
        const ticketCreationMonth = created_on_date.getMonth();
        const ticketCreationDay = created_on_date.getDate();

        if (!groupings.values[ticketCreationYear]) {
            groupings.values[ticketCreationYear] = {
                title: ticketCreationYear,
                count: 0,
                values: {},
                sub_columns: [
                    { title: 'Month', field: 'title' },
                    { title: 'Count', field: 'count', align: 'right' },
                ]
            };
        }
        if (!groupings.values[ticketCreationYear].values[ticketCreationMonth]) {
            groupings.values[ticketCreationYear].values[ticketCreationMonth] = {
                title: created_on_date.toLocaleString('default', { month: 'long' }),
                order_value: ticketCreationMonth,
                count: 0,
                values: {},
                sub_columns: [
                    { title: 'Day', field: 'title' },
                    { title: 'Count', field: 'count', align: 'right' },
                ]
            };
        }
        if (!groupings.values[ticketCreationYear].values[ticketCreationMonth].values[ticketCreationDay]) {
            groupings.values[ticketCreationYear].values[ticketCreationMonth].values[ticketCreationDay] = {
                title: ticketCreationDay,
                count: 0,
                values: {},
                sub_columns: [
                    { title: 'Number', field: 'number' },
                    { title: 'Description', field: 'short_description' },
                ]
            };
        }
        groupings.values[ticketCreationYear].values[ticketCreationMonth].values[ticketCreationDay].values[ticket_id] = ticket;
        groupings.values[ticketCreationYear].values[ticketCreationMonth].values[ticketCreationDay].count += 1;
        groupings.values[ticketCreationYear].values[ticketCreationMonth].count += 1;
        groupings.values[ticketCreationYear].count += 1;
    });
    return groupings;
};





export default function TicketReport(props) {
    const auth = useAuthFunctions()
    const [expanded, setExpanded] = React.useState({});
    const [loading, setLoading] = React.useState(true)
    const [reportData, setReportData] = React.useState({})
    const [groupedTickets, setGroupedTickets] = React.useState({})

    const processTicketDataResult = React.useCallback((data) => {
        setReportData(data)
        const tempGroupedTickets = groupTicketsByTemporalGroups(data.content.tickets)
        setGroupedTickets(tempGroupedTickets)
        setLoading(false)
    }, [auth, setReportData, setGroupedTickets, setLoading])

    const export_to_excel = () => {
        var data = []
        Object.entries(reportData.content.tickets).forEach(([ticketId, ticket]) => {
            var tmepticket = ticket
            var assigned_user = reportData.content.relevant_users[ticket.assigned_to]
            for (var key in assigned_user) {
                if (assigned_user.hasOwnProperty(key)) {
                    tmepticket["assigned_user_" + key] = assigned_user[key]
                }
            }
            data.push(ticket)
        })
        exportToExcel(data, "tickets")
    }


    React.useEffect(() => {
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/ticketreport`, "POST", {}).then((result) => {
            processTicketDataResult(result)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {loading ? <CircularProgress /> :
                    <Button variant="contained" color="primary" onClick={export_to_excel}>
                        Download Report
                    </Button>
                }
            </Box>
            <RecursiveExpandingTable data={groupedTickets} loading={loading} />
        </>
    )
}


function RecursiveExpandingTable(props) {
    const data = props.data.values
    const columns = props.data.sub_columns
    const loading = props.loading || false
    return (
        <>
            {loading ? <CircularProgress /> :
                <LoadingTableBase loading={loading}>
                    <TableHead>
                        <TableRow>
                            <TableCell key={"primary_title"}></TableCell>
                            {columns.map((column) => (
                                <TableCell key={column.title} align={column.align || "left"}>
                                    {column.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(data).map(([elementKey, elementValue]) => (
                            <ExpandingRow
                                elementKey={elementKey}
                                data={data[elementKey]}
                                columns={columns}
                            />
                        ))}
                    </TableBody>
                </LoadingTableBase>
            }
        </>
    )
}

function ExpandingRow(props) {
    const isExpandable = props.data && props.data.values && Object.keys(props.data.values).length > 0
    const [open, setOpen] = React.useState(false);
    const handleClickExpandy = React.useCallback(() => {
        setOpen(!open);
    }, [open, setOpen])
    console.log(props.elementKey + "expand")
    return (
        <>
            <TableRow key={props.elementKey} sx={{ '& > *': { borderBottom: 'unset' } }} >
                <TableCell padding="checkbox" key={"expander"}>
                    {isExpandable &&
                        <IconButton onClick={handleClickExpandy}>
                            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    }
                </TableCell>
                {
                    props.columns.map((column) => (
                        <TableCell key={column.title} align={column.align || "left"}>
                            {props.data[column.field]}
                        </TableCell>
                    ))
                }
            </TableRow>
            <TableRow key={props.elementKey + "expand"}>
                <TableCell key={"onlythinghere"} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: '20px 0' }}>
                            {
                                open && props.data ?
                                    <RecursiveExpandingTable data={props.data} loading={false} />
                                    : <></>
                            }
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}



