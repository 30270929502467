import React from "react";

import { default as ConversationMessages } from "../ConversationMessages";
import { default as ConversationHeader } from "../ConversationHeader";
import { default as ConversationInput } from "../ConversationInput";

export default function Conversation(props) {
  const topElementRef = React.useRef();
  const bottomElementRef = React.useRef();
  const [containerMinHeight, setContainerMinHeight] = React.useState('500px');
  const conversationContainerStyle = {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: "column",
    msFlexDirection: "column",
    minHeight: containerMinHeight,
    boxSizing: 'border-box',
  };

  const updateContainerMinHeight = React.useCallback(() => {
    const topHeight = topElementRef.current ? topElementRef.current.offsetHeight : 0;
    const bottomHeight = bottomElementRef.current ? bottomElementRef.current.offsetHeight : 0;
    const viewportHeight = window.innerHeight;
    //TODO: use 100% as the base if we are in widget mode. Ravi please help
    setContainerMinHeight(`calc(${viewportHeight}px - ${topHeight + bottomHeight}px - 70px)`);
  }, [setContainerMinHeight]);

  React.useEffect(() => {
    //scrollToBottom();
    updateContainerMinHeight();
    window.addEventListener('resize', updateContainerMinHeight);
    return () => {
      window.removeEventListener('resize', updateContainerMinHeight);
    };
  }, []);

  return (
    <>
      <div ref={topElementRef} />
      <div style={conversationContainerStyle}>
        <ConversationHeader />
        <ConversationMessages />
        <ConversationInput />
      </div>
      <div ref={bottomElementRef} />
    </>
  );
}
