import React from 'react'

import { GenericReducer, GenericReducerFunctionMap } from '../../pld-helpers';

import { defaultConfig } from '..';

const contentContext = React.createContext();
export function useChatContext() {
    var context = React.useContext(contentContext);
    if (context === undefined) {
        throw new Error("useChatContext must be used within an ChatProvider");
    }
    return context;
}

const dispatchContext = React.createContext();
export function useChatDispatchContext() {
    var context = React.useContext(dispatchContext);
    if (context === undefined) {
        throw new Error("ChatDispatchContext must be used within an ChatProvider");
    }
    return context;
}
// #endregion

// #region ChatFunctions

function LogSettings(context, dispatch) {
    console.log("context", context)
    console.log("dispatch", dispatch)
}

function LauncherSelected(context, dispatch) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        "isOpen": !context.isOpen
    });
}

function CloseChat(context, dispatch) {
    console.log("CloseChat")
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        "isOpen": false
    });
}

function IncrementBadgeContent(context, dispatch) {
    let newBadgeContent = context.badgeContent;
    if (newBadgeContent) {newBadgeContent++;}
    else {newBadgeContent = 1;}
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        "badgeContent": newBadgeContent
    });
}

function ClearBadgeContent(context, dispatch) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        "badgeContent": undefined
    });
}

function setInputText(context, dispatch, text) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        "inputText": text
    });
}

function sendMessage(context, dispatch) {
    console.log("sendMessage", context.inputText)
    if (context.inputText) {
        context.handleNewUserMessage(context.inputText);
        GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
            "messages": [...context.messages, {
                "content": context.inputText,
                "type": "text",
                "source": "user"
            }],
            "inputText": ''
        });
        // GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        //     "inputText": ""
        // });
    }
}

function setConfigValue(context, dispatch, key, value) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        [key]: value
    });
}

function clearMessages(context, dispatch) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        "messages": []
    });
}

function addResponseMessage(context, dispatch, text) {
    if (typeof text !== "string") {
        console.log("bad type", typeof text, text)
        return;
    }
    console.log("good type", typeof text, text)
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        "messages": [...context.messages, {
            "content": text,
            "type": "text",
            "source": "system"
        }]
    });
}

function addUserMessage(context, dispatch, text) {
    if (typeof text !== "string") {
        console.log("bad type", typeof text, text)
        return;
    }
    console.log("good type", typeof text, text)
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        "messages": [...context.messages, {
            "content": text,
            "type": "text",
            "source": "user"
        }]
    });
}

function updateFromConfig(context, dispatch, config) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap(config);
}

// #endregion

export function useChatFunctions() {
    const context = useChatContext()
    const dispatch = useChatDispatchContext()
    const Functions = {
        logSettings: () => LogSettings(context, dispatch),
        launcherSelected: () => LauncherSelected(context, dispatch),
        incrementBadgeContent: () => IncrementBadgeContent(context, dispatch),
        clearBadgeContent: () => ClearBadgeContent(context, dispatch),
        closeChat: () => CloseChat(context, dispatch),
        setInputText: (text) => setInputText(context, dispatch, text),
        sendMessage: () => sendMessage(context, dispatch),
        setConfigValue: (key, value) => setConfigValue(context, dispatch, key, value),
        updateFromConfig: (config) => updateFromConfig(context, dispatch, config),

        clearMessages: () => clearMessages(context, dispatch),
        addResponseMessage: (text) => addResponseMessage(context, dispatch, text),
        addUserMessage: (text) => addUserMessage(context, dispatch, text),
        markAllAsRead: () => console.log("markAllAsRead"),
    }
    return Functions
}
export function useChatSettings() {
    const context = useChatContext()
    return context
}

export function ChatProvider({ children, ...rest }) {
    const [chatContextState, chatContextDispatch] = React.useReducer(GenericReducer, defaultConfig);
    return (
        <contentContext.Provider value={chatContextState}>
            <dispatchContext.Provider value={chatContextDispatch}>
                {children}
            </dispatchContext.Provider>
        </contentContext.Provider>
    );
}









