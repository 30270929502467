import React from 'react'
//import ControlFrame from '../components/ControlFrame'
import ChatWidgetV2 from '../components/ChatWidgetV2'



// eslint-disable-next-line
export default function () {
    return (
        <div>
            <ChatWidgetV2 />
        </div>
    )
}
