import * as React from 'react';

import { useAuthFunctions } from '../../../library/pld-auth'
import * as utils from '../../../utils'

import {
    LoadingTableBase,
    TableCell, TableHead, TableRow, TableBody, 
    Accordion, AccordionSummary, AccordionDetails,
    ExpandMoreIcon
} from '../../../library/pld-ui'

export default function RoomManagement(props) {
    const auth = useAuthFunctions()
    const [loading, setLoading] = React.useState(true)
    const [rooms, setRooms] = React.useState([])

    const processDataResult = React.useCallback((data) => {
        setRooms(data)
        setLoading(false)
    }, [setLoading, setRooms])


    React.useEffect(() => {
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/rooms`, "POST", {}).then((result) => {
            console.log("rooms_initial", result)
            processDataResult(result)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <LoadingTableBase loading={loading}>
                <TableHead>
                    <TableRow>
                        <TableCell>Room Id</TableCell>
                        <TableCell>Room Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rooms.map((item, index) => (
                        <Accordion key={index}>
                            <AccordionSummary 
                                expandIcon={<ExpandMoreIcon />} >
                        <TableRow
                            key={index} 
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {item.id}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.title}
                            </TableCell>
                        </TableRow>
                        </AccordionSummary>
                        <AccordionDetails>
                            <h1>Some stuff here!</h1>
                        </AccordionDetails>
                        </Accordion>
                    ))}
                </TableBody>
            </LoadingTableBase>
        </>
    );
}

