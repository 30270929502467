import React from "react";

import {
  useChatSettings,
  Message
} from "../..";
import {
  List, 
} from "../../../pld-ui"

export default function ConversationMessages(props) {
  const chatSettings = useChatSettings();
  const messages = chatSettings.messages;
  const topElementRef = React.useRef();
  const bottomElementRef = React.useRef();
  const [containerMinHeight, setContainerMinHeight] = React.useState('100vh');
  const messagesStartRef = React.useRef(null);
  const messagesEndRef = React.useRef(null);

  const conversationContainerStyle = {
    display: 'flex',
    width: '100%',
    background: chatSettings.conversationContainerBackground,
    // height: chatSettings.conversationContainerHeight,
    overflowY: 'scroll',
    flexDirection: 'column',
    flex: 1,
  };

  const endDivStyle = {
    flexGrow: 1,
    overflow: 'auto',
    // marginTop: 'auto'
  }

  const conversationListStyle = {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    flex: 1,
    // height: "100vh",
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const updateContainerMinHeight = React.useCallback(() => {
    const topHeight = topElementRef.current ? topElementRef.current.offsetHeight : 0;
    const bottomHeight = bottomElementRef.current ? bottomElementRef.current.offsetHeight : 0;
    setContainerMinHeight(`calc(100vh - ${topHeight + bottomHeight}px)`);
  }, [setContainerMinHeight]);

  React.useEffect(() => {
    scrollToBottom();
    updateContainerMinHeight();
    window.addEventListener('resize', updateContainerMinHeight);
    return () => {
      window.removeEventListener('resize', updateContainerMinHeight);
    };
  }, []);

  return (
    <>
      <div ref={topElementRef} />
      {chatSettings.displayMessages && (
        
        <div style={conversationContainerStyle}>
          <List style={conversationListStyle}>
            <div ref={messagesStartRef} />
            {messages.map((message, index) => {
              return (
                <Message message={message} index={index} key={index}/>
              );
            })}
            <div ref={messagesEndRef} style={endDivStyle}/>
          </List>
          <div style={endDivStyle} />
        </div>
      )}
      <div ref={bottomElementRef} />
    </>

  );
}
