import { lighten } from '../library/pld-ui';
import defaultHostConfig from "./default";

//// Updating default hostConfig based on MUI theme
const createHostConfig = (muiTheme) => {

    var appConfig = defaultHostConfig
    const adavptiveCardPaddings = muiTheme.spacing(2)

    /// padding
    appConfig = {
        ...appConfig,
        spacing: {
            ...appConfig.spacing,
            padding: adavptiveCardPaddings.replace('px', '')
        }
    }

    /// font family
    appConfig = {
        ...appConfig,
        fontTypes: {
            ...appConfig.fontTypes,
            default: {
                ...appConfig.fontTypes.default,
                fontFamily: muiTheme.typography.fontFamily,
                fontSizes: {
                    ...appConfig.fontTypes.default.fontSizes,
                    default: muiTheme.typography.fontSize
                }
            }
        }
    }

    /// Container style from pallete
    appConfig = {
        ...appConfig,
        containerStyles: {
            ...appConfig.containerStyles,
            default: {
                ...appConfig.containerStyles.default,
                backgroundColor: muiTheme.palette.primary.main,
                foregroundColors: {
                    ...appConfig.containerStyles.default.foregroundColors,
                    default: {
                        ...appConfig.containerStyles.default.foregroundColors.default,
                        default: muiTheme.palette.text.primary
                    }
                }
            },
            emphasis: {
                ...appConfig.containerStyles.emphasis,
                backgroundColor: lighten(muiTheme.palette.primary.main, .25),
                foregroundColors: {
                    ...appConfig.containerStyles.emphasis.foregroundColors,
                    default: {
                        ...appConfig.containerStyles.emphasis.foregroundColors.default,
                        default: muiTheme.palette.text.primary
                    }
                }
            },
        }
    }

    /// Allow Custom Style 
    appConfig = {
        ...appConfig,
        adaptiveCard: {
            ...appConfig.adaptiveCard,
            allowCustomStyle: true
        }
    }

    /// actions 
    appConfig = {
        ...appConfig,
        actions: {
            ...appConfig.actions,
            maxActions: 6,
            actionsOrientation: 'vertical',
            actionAlignment: 'stretch'
        }
    }

    return appConfig
}

export { createHostConfig }