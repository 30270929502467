import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import AI from '../pages/ai';
import Admin from '../pages/Admin';
import Iframe from '../pages/Iframe';
import widget from '../pages/widget';
import msteamsapp from '../pages/msteamsapp';
import fullscreen from '../pages/fullscreen';

export default function Routes() {
    return (
        <Router>
            <Switch>
                <Route path="/pldchatcontrolframe" component={Iframe} />
                <Route path="/msteamsapp" component={msteamsapp} />
                <Route path="/fullscreen" component={fullscreen} />
                <Route path="/admin/*" component={Admin} />
                <Route path="/admin" component={Admin} />
                <Route path="/ai/*" component={AI} />
                <Route path="/ai" component={AI} />
                <Route component={widget} />
            </Switch>
        </Router>
    )
}